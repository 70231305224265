import type { IMicrocopyPluralisation } from "@contentful-api/types/contentful";

const pluralizeReplacementString = (key: string) => `[[${key}]]`;

// Example: interpolatePluralWords("80 of 120 [[productsPlural]]", "more", [{ key: "productsPlural", value0: "products", value1: "product", valueMore: "products" }])
// Result: "80 of 120 products"
export const interpolatePluralWords = (
    text: string,
    plural: string,
    items: IMicrocopyPluralisation[]
) => {
    items.forEach((pluralWord) => {
        const _pluralWord = pluralWord?.fields;
        // Find the key that matches the plural string
        // e.g. "more" => "valueMore"
        const pluralWorldKey: string = Object.keys(_pluralWord).find((key) =>
            key.toLocaleLowerCase().includes(plural)
        );

        text = text.replaceAll(
            pluralizeReplacementString(_pluralWord?.key),
            _pluralWord[pluralWorldKey]
        );
    });

    return text;
};
