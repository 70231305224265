const OrderConfirmation = {
    title: "orderConfirmation.title",
    gratitude: "orderConfirmation.gratitude",
    orderInfo: {
        orderDate: "orderConfirmation.orderDate",
        orderNumber: "orderConfirmation.orderNumber",
        paymentMethod: "orderConfirmation.orderInfo.paymentMethod",
        deliveryMethod: "orderConfirmation.orderInfo.deliveryMethod",
        billingAddress: "orderConfirmation.orderInfo.billingAddress",
        shippingAddress: "orderConfirmation.orderInfo.shippingAddress",
        contactDetails: "orderConfirmation.orderInfo.contactDetails",
    },
    shoppingBagSummaryTitle: "orderConfirmation.shoppingBag.summary.title",
    unauthorizedPanel: {
        title: "orderConfirmation.unauthorizedPanel.title",
    },
    authorizedPanel: {
        thankYouText: "orderConfirmation.authorizedPanel.thankYouText",
    },
};

export default OrderConfirmation;
