import type { FormErrorMessageProps as _FormErrorMessageProps } from "@chakra-ui/react";
import {
    forwardRef,
    FormErrorMessage as _FormErrorMessage,
    FormErrorIcon as _FormErrorIcon,
} from "@chakra-ui/react";

export interface FormErrorMessageProps extends _FormErrorMessageProps {}

const FormErrorStyles = {
    color: "red.200",
    fontSize: "desktopBodyTextTiny",
    fontWeight: "semibold",
} as FormErrorMessageProps["sx"];

export const FormErrorMessage = forwardRef<FormErrorMessageProps, "div">(
    ({ sx, ...props }, ref) => {
        return <_FormErrorMessage ref={ref} sx={{ ...FormErrorStyles, ...sx }} {...props} />;
    }
);

export const FormErrorIcon = _FormErrorIcon;
