import colors from "./01.colors/colors";
import typography from "./02.typography";
import space from "./03.space/space";
import sizes from "./04.sizes/sizes";
import radii from "./05.radii/radii";
import borders from "./06.borders/borders";
import opacities from "./07.opacities/opacities";
import shadows from "./08.shadows/shadows";
import breakpoints from "./09.breakpoints/breakpoints";
import zIndices from "./10.z-indices/z-indices";
import aspectRatios from "./11.aspect-ratios/aspect-ratios";
import transitions from "./12.transitions/transitions";

export {
    colors,
    typography,
    space,
    sizes,
    radii,
    borders,
    opacities,
    shadows,
    breakpoints,
    zIndices,
    aspectRatios,
    transitions,
};
