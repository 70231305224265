import type { ListProps as _ListProps, ListItemProps as _ListItemProps } from "@chakra-ui/react";
import {
    forwardRef,
    List as _List,
    ListItem as _ListItem,
    OrderedList as _OrderedList,
    UnorderedList as _UnorderedList,
    ListIcon as _ListIcon,
} from "@chakra-ui/react";

import type { IconProps } from "../../media-and-icons/icon/Icon";

export interface ListProps extends _ListProps {}
export interface ListItemProps extends _ListItemProps {}
export interface ListIconProps extends IconProps {}

export const List = forwardRef<ListProps, "ul">(({ ...props }, ref) => (
    <_List ref={ref} {...props} />
));

export const OrderedList = forwardRef<ListProps, "ol">(({ ...props }, ref) => (
    <_OrderedList ref={ref} {...props} />
));

export const UnorderedList = forwardRef<ListProps, "ul">(({ ...props }, ref) => (
    <_UnorderedList ref={ref} {...props} />
));

export const ListItem = forwardRef<ListItemProps, "li">(({ ...props }, ref) => (
    <_ListItem ref={ref} {...props} />
));

export const ListIcon = forwardRef<ListIconProps, "svg">(({ css, ...props }, ref) => (
    <_ListIcon ref={ref} role="presentation" {...props} />
));
