import { forwardRef } from "react";
import type { ILink, IPagePath } from "@contentful-api/types/contentful";
import { Link, type LinkProps } from "@ui/components/navigation/link/Link";
import { isEmpty } from "lodash-es";
interface IExternalUrlProps extends LinkProps {
    link: ILink | IPagePath;
}

export const ExternalUrl = forwardRef<HTMLAnchorElement, IExternalUrlProps>(
    ({ link, children, ...rest }, ref) => {
        const _link = link as ILink;

        const fields = _link?.fields;
        const internalLinkOnNewTab = fields?.internalLinkOnNewTab;
        const target = internalLinkOnNewTab ? "_blank" : "_self";

        const href = _link?.fields?.externalUrl || "";
        const label = _link?.fields?.label || "";
        //TODO Bryan - hover
        const isJumpLink = href.startsWith("#");
        const content = isEmpty(children) ? label : children;
        return (
            <Link
                href={href}
                target={!isJumpLink ? target : undefined}
                rel="noopener noreferrer"
                sx={{ _hover: { color: "gray.500" } }}
                ref={ref}
                {...rest}
            >
                {content}
            </Link>
        );
    }
);

ExternalUrl.displayName = "ExternalUrl";
