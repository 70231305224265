import type { TextProps as _TextProps } from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/react";
import { Text as _Text } from "@chakra-ui/layout";
export interface TextProps extends _TextProps {
    text?: string;
}

export const Text = forwardRef<TextProps, "p">(({ ...props }, ref) => (
    <_Text ref={ref} {...props} />
));
