const ClickAndCollect = {
    available: "uiText.clickAndCollect.available",
    unavailable: "uiText.clickAndCollect.unavailable",
    availableSelectedStore: "uiText.clickAndCollect.availableSelectedStore",
    availableSoon: "uiText.clickAndCollect.availableSoon",
    availableSoonSelectedStore: "uiText.clickAndCollect.availableSoonSelectedStore",
    eccoStore: "uiText.ClickAndCollect.eccoStore",
    stores: "uiText.stores",
    changeStore: "uiText.clickAndCollect.changeStore",
    selectStore: "uiText.clickAndCollect.selectStore",
    headline: "uiText.clickAndCollect.headline",
    storeLocatorDescription: "uiText.clickAndCollect.storeLocator.description",
    storeLocatorList: "uiText.storeLocator.list",
    storeLocatorMap: "uiText.storeLocator.map",
    storeLocatorDirections: "storeLocator.directions",
    storeLocatorOpen: "storeLocator.open",
    storeLocatorOpens: "storeLocator.opens",
    storeLocatorClose: "storeLocator.close",
    storeLocatorClosed: "storeLocator.closed",
    storeLocatorPlaceholder: "storeLocator.query.placeholder",
    storeLocatorReset: "storeLocator.query.reset",
    noStoresFound: "storeLocator.query.zero",
    homeDelivery: "uiText.clickAndCollect.homeDelivery",
    availableInStores: "uiText.clickAndCollect.availableInStores",
    unavailableInStores: "uiText.clickAndCollect.itemsUnavailable",
    SortBy: "uiText.sortBy",
    FilterBy: "uiText.filterBy",
    storesWithin: "uiText.storesWithin",
    distance: "uiText.distance",
    eccoStores: "uiText.eccoStores",
    workingDays: {
        sunday: "storeLocator.weekday.sunday",
        monday: "storeLocator.weekday.monday",
        tuesday: "storeLocator.weekday.tuesday",
        wednesday: "storeLocator.weekday.wednesday",
        thursday: "storeLocator.weekday.thursday",
        friday: "storeLocator.weekday.friday",
        saturday: "storeLocator.weekday.saturday",
    },
    defaultWorkingDay: "storeLocator.weekday.default",
};

export default ClickAndCollect;
