const aspectRatios = {
    "1:1": "1 / 1",
    "3:2": "3 / 2",
    "4:5": "4 / 5",
    "4:3": "4 / 3",
    "6:7": "6 / 7",
    "16:9": "16 / 9",
};

export default aspectRatios;
