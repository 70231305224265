import { createStandaloneToast, useChakra, useLatestRef } from "@chakra-ui/react";
import type { UseToastOptions as _UseToastOptions } from "@chakra-ui/react";
import type { ThemeTypings } from "@chakra-ui/styled-system";
import { useMemo } from "react";

import { Icon } from "../../media-and-icons/icon/Icon";
import type { IconProps } from "../../media-and-icons/icon/Icon";

export const CheckIcon: React.FC<IconProps> = ({ css, ...props }) => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
        />
    </Icon>
);

export const WarningIcon: React.FC<IconProps> = ({ css, ...props }) => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
        />
    </Icon>
);

export const STATUSES = {
    warning: { icon: WarningIcon, colorScheme: "yellow" },
    success: { icon: CheckIcon, colorScheme: "green" },
    error: { icon: WarningIcon, colorScheme: "red" },
};

export type AlertStatus = keyof typeof STATUSES;

type LogicalToastPosition = "top-start" | "top-end" | "bottom-start" | "bottom-end";

type ToastPositionWithLogical =
    | LogicalToastPosition
    | "top"
    | "top-left"
    | "top-right"
    | "bottom"
    | "bottom-left"
    | "bottom-right";

export type ToastPosition = Exclude<ToastPositionWithLogical, LogicalToastPosition>;

export type WithoutLogicalPosition<T> = Omit<T, "position"> & {
    position?: ToastPosition;
};

type LogicalPlacementMap = Record<LogicalToastPosition, { ltr: ToastPosition; rtl: ToastPosition }>;

export function getToastPlacement(
    position: ToastPositionWithLogical | undefined,
    dir: "ltr" | "rtl"
): ToastPosition | undefined {
    const logicals: LogicalPlacementMap = {
        "top-start": { ltr: "top-left", rtl: "top-right" },
        "top-end": { ltr: "top-right", rtl: "top-left" },
        "bottom-start": { ltr: "bottom-left", rtl: "bottom-right" },
        "bottom-end": { ltr: "bottom-right", rtl: "bottom-left" },
    };

    const logical = logicals[position as keyof typeof logicals];

    if (logical) {
        return logical[dir];
    }

    return position as ToastPosition;
}

const defaults = {
    duration: 5000,
    position: "bottom",
    variant: "solid",
} as const;

export interface UseToastOptions extends Omit<_UseToastOptions, "variant" | "status"> {
    variant?: "subtle" | "solid";
    status?: AlertStatus;
    colorScheme?: ThemeTypings["colorSchemes"];
}

export function useToast(options?: UseToastOptions) {
    const { theme } = useChakra();

    const toastOptions = useLatestRef(options);

    return useMemo(() => {
        return createStandaloneToast({
            theme,
            defaultOptions: { ...defaults, ...toastOptions.current },
        });
    }, [theme, toastOptions]);
}
