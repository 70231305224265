import type { InputProps as _InputProps } from "@chakra-ui/react";
import { forwardRef, Input as _Input } from "@chakra-ui/react";

export interface InputProps extends _InputProps {}

export const Input = forwardRef<InputProps, "input">(({ ...props }, ref) => (
    <_Input ref={ref} {...props} height={["9.6", "10.4"]} py={["2.5", "2.8"]} px={["3", "4"]} />
));

// This is used in `input-group.tsx`
Input.id = "Input";
