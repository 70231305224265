import { useMediaQuery } from "@chakra-ui/react";
import breakpoints from "../design-tokens/09.breakpoints/breakpoints";
import debounce from "lodash-es/debounce";
import { useEffect, useRef, useState } from "react";

type BreakpointKeys = keyof typeof breakpoints;

type UseThemeMediaQueryResult = {
    [key in BreakpointKeys]: boolean;
} & { custom: boolean[] };

type MaybeThemeMediaQueryResult = {
    [key in BreakpointKeys]?: boolean;
} & { custom?: boolean[] };

export const useRawThemeMediaQuery = (
    customQueries: string | string[] = []
): UseThemeMediaQueryResult => {
    const themeBreakpointQueries = Object.values(breakpoints).map((v) => `(min-width: ${v})`);
    const themeBreakpointKeys = Object.keys(breakpoints) as Array<BreakpointKeys>;

    const initialFalseValues: MaybeThemeMediaQueryResult = {};
    themeBreakpointKeys.map((k) => {
        initialFalseValues[k] = false;
    });

    const allQueries = [
        ...themeBreakpointQueries,
        ...(Array.isArray(customQueries) ? customQueries : [customQueries]),
    ];

    const [...all] = useMediaQuery(allQueries);

    const themeBreakpointResults = all.slice(0, themeBreakpointQueries.length);
    const otherResults = all.slice(themeBreakpointQueries.length);

    const almostResult: MaybeThemeMediaQueryResult = {
        ...initialFalseValues,
        custom: otherResults ? [...otherResults] : [],
    };

    themeBreakpointKeys.forEach((k, i) => (almostResult[k] = themeBreakpointResults[i]));

    return { ...(almostResult as UseThemeMediaQueryResult) };
};

export const useThemeMediaQuery = (
    customQueries: string | string[] = []
): UseThemeMediaQueryResult => {
    const rawMediaQuery = useRawThemeMediaQuery(customQueries);
    const [debouncedMediaQuery, setDebouncedMediaQuery] = useState(rawMediaQuery);
    const [isResizing, setIsResizing] = useState(false);

    const debouncedSetResult = useRef(
        debounce((res) => {
            setDebouncedMediaQuery(res);
            setIsResizing(false);
        }, 100)
    ).current;

    useEffect(() => {
        const handleResize = () => {
            setIsResizing(true);
            debouncedSetResult(rawMediaQuery);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [customQueries]);

    return isResizing ? debouncedMediaQuery : rawMediaQuery;
};
