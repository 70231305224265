import type {
    LinkOverlayProps as _LinkOverlayProps,
    LinkBoxProps as _LinkBoxProps,
} from "@chakra-ui/react";
import { forwardRef, LinkOverlay as _LinkOverlay, LinkBox as _LinkBox } from "@chakra-ui/react";

export interface LinkOverlayProps extends _LinkOverlayProps {}

export const LinkOverlay = forwardRef<LinkOverlayProps, "a">(({ ...props }, ref) => (
    <_LinkOverlay ref={ref} {...props} />
));

export type LinkBoxProps = _LinkBoxProps;

export const LinkBox = forwardRef<LinkBoxProps, "div">(({ ...props }, ref) => (
    <_LinkBox ref={ref} {...props} />
));
