const tinyTextStyles = {
    color: "black",
    fontSize: "desktopBodyTextTiny",
    fontWeight: "semibold",
};

export const styles = {
    fieldsWrapper: {
        w: {
            base: "100%",
            md: "60%",
        },
        mt: 8,
    },
    formLabel: {
        fontWeight: "bold",
        fontSize: ["mobileBodyB2", "desktopBodyB2"],
    },
    policyAgreementLabel: {
        m: 0,

        "& p": {
            ...tinyTextStyles,
        },

        "& span": {
            ...tinyTextStyles,
        },
    },
    formInput: {
        fontWeight: "bold",
        fontSize: ["mobileBodyB2", "desktopBodyB2"],
        color: "gray.500",
        // Due our fontSize on the formInput, we are using a workaround with scale on the input primitive component.
        // For that reason on mobile we are using a magic number to be close to the value on the design. Check @packages/ui/src/components/forms/input/Input.styles for more information
        h: ["55px", 10.4],
        _readOnly: {
            bg: "gray.50",
            cursor: "not-allowed",
            borderColor: "gray.400",
        },
        _selected: {
            outline: "none",
        },
    },
    birthdate: {
        fontWeight: "bold",
        fontSize: ["mobileBodyB2", "desktopBodyB2"],
        color: "gray.500",
        _placeholder: { color: "gray.200", fontSize: " desktopBodyTextSmall" },
        _selected: {
            outline: "none",
        },
    },
    formInputDisabled: {
        bg: "gray.50",
    },
    genderField: {
        size: "lg",
        fontWeight: "bold",
        fontSize: ["mobileBodyB2", "desktopBodyB2"],
        color: "gray.500",
    },
    errorGroupMessage: {
        color: "red.200",
        fontSize: "desktopBodyTextTiny",
        fontWeight: "bold",
        mt: 2,
    },
    countryCodeField: {
        color: "gray.800",
        fontWeight: "bold",
        h: ["55px", 10.4],
        border: "1px solid gray.100",
    },
};
