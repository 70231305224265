import { Logger, LogTag, ServiceType } from "@lib/monitoring/logger";
import type { IMicrocopy, IMicrocopySet } from "@contentful-api/types/contentful";
import type { Replacements } from "@lib/utils/interpolateText";
import { interpolateText } from "@lib/utils/interpolateText";
import { isEmpty, get as _get } from "lodash-es";
import { createContext, useContext } from "react";
import { interpolatePluralWords } from "./helpers/interpolatePluralWords";

export type MicrocopySetKey = "global" | "page" | string;

export type MicrocopyGetOptions = {
    replacements?: Replacements;
    pluralize?: "0" | "1" | "more";
    fromMultiple?: boolean;
};

export interface MicrocopyContextState {
    global: IMicrocopySet;
    page: IMicrocopySet;
    [key: string]: IMicrocopySet;
}

export interface MicrocopyContextAPI {
    get: (
        microcopySetKey: MicrocopySetKey,
        microcopyKey: string,
        options?: MicrocopyGetOptions
    ) => string | null;
    getMultiple: (
        microcopySetKey: MicrocopySetKey,
        microcopyKeys: string[],
        options?: MicrocopyGetOptions
    ) => { [key: string]: string } | null;
}

export const pluralKeys = {
    0: "0",
    1: "1",
    more: "more",
};

const defaultPluralKey = pluralKeys[1];

export const pluralizeHelper = (value: number): "0" | "1" | "more" => {
    return pluralKeys[value] || pluralKeys.more;
};

const MicrocopyContext = createContext<MicrocopyContextState | undefined>(undefined);
const { Provider: MicrocopyProvider } = MicrocopyContext;

const useMicrocopy = (): MicrocopyContextAPI => {
    const context = useContext(MicrocopyContext);

    if (context === undefined) {
        throw new Error("useMicrocopy must be within MicrocopyProvider");
    }

    const get = (
        microcopySetKey: MicrocopySetKey,
        microcopyKey: string,
        options?: MicrocopyGetOptions
    ): string | null => {
        const temporaryWindow: any = typeof window !== "undefined" ? window : {};
        const pathName = temporaryWindow.location?.pathname;
        const microcopySet: IMicrocopySet = _get(context, microcopySetKey);
        const missingSetKeyMessage = `Missing Microcopy Set: ${microcopySetKey}`;
        const missingMicrocopyMessage = `Missing Microcopy: ${microcopyKey} missing in ${microcopySetKey}`;
        const mcType = options?.fromMultiple ? "getMultiple" : "getSingle";
        const MCLogger = (key, set, message) => {
            const errorName =
                message === missingSetKeyMessage ? "Missing Microcopy Set" : "Missing Microcopy";
            Logger.warn(ServiceType.CONTENTFUL, `Miss Microcopy  ${key} in ${set}`, {
                tag: LogTag.MICROCOPY,
                key: key,
                set: set,
                pathName: pathName,
                type: mcType,
                error: {
                    name: errorName,
                },
            });
        };
        if (!microcopySet || !microcopySet?.fields?.microcopy) {
            MCLogger(microcopyKey, microcopySetKey, missingSetKeyMessage);
            return null;
        }

        const result = microcopySet?.fields?.microcopy?.find(
            (microcopy: IMicrocopy) => microcopy?.fields?.key === microcopyKey
        );

        if (result) {
            const { replacements, pluralize } = options || { pluralize: "" };
            let resultValue = result?.fields?.value;

            if (!isEmpty(replacements)) {
                resultValue = interpolateText(resultValue, replacements);
            }

            if (!isEmpty(pluralize) && !isEmpty(result?.fields?.pluralisation)) {
                resultValue = interpolatePluralWords(
                    resultValue,
                    pluralize,
                    result?.fields?.pluralisation
                );
                // If pluralize value is not provided and there are pluralisation items, use the default plural key
            } else if (!isEmpty(result?.fields?.pluralisation)) {
                resultValue = interpolatePluralWords(
                    resultValue,
                    defaultPluralKey,
                    result?.fields?.pluralisation
                );
            }

            return resultValue;
        } else {
            // Remove error logs from unit tests
            // eslint-disable-next-line turbo/no-undeclared-env-vars
            if (!process.env.JEST_WORKER_ID && !options?.fromMultiple) {
                MCLogger(microcopyKey, microcopySetKey, missingMicrocopyMessage);
            }
            return null;
        }
    };

    const getMultiple = (
        microcopySetKey: MicrocopySetKey,
        microcopyKeys: string[],
        options?: MicrocopyGetOptions
    ): Record<string, string> | null => {
        const result = {};
        const getOptions = options || {};
        getOptions.fromMultiple = true;
        microcopyKeys.forEach((key) => {
            const microcopy = get(microcopySetKey, key, getOptions);

            if (microcopy) {
                result[key] = microcopy;
            }
        });
        return result;
    };

    return {
        get,
        getMultiple,
    };
};

export { MicrocopyContext, MicrocopyProvider, useMicrocopy };
