export const removeProps = (props: any, ...propNames: string[]) => {
    const newProps = { ...props };
    propNames.forEach((propName) => {
        delete newProps[propName];
    });
    return newProps;
};

export const removePropsFromHTML = (props: any) => {
    const propNames = ["isLeftColumn", "isRightColumn", "authorizationInvitation"];
    return removeProps(props, ...propNames);
};

export default { removeProps, removePropsFromHTML };
