import { isEmpty } from "lodash-es";
import { forwardRef } from "react";
import { Flex } from "@ui/components/layout/flex/Flex";
import { PagePath } from "@components/cms/link/PagePath";
import { ModalLink } from "@components/cms/link/ModalLink";
import type { TextProps } from "@ui/components/content/text/Text";
import { ExternalUrl } from "@components/cms/link/ExternalUrl";
import type { LinkProps } from "@ui/components/navigation/link/Link";
import { Text } from "@ui/components/content/text/Text";
import { InternalLink } from "@components/cms/link/InternalLink";
import type { ILink, IPagePath } from "@contentful-api/types/contentful";
import type { NextLinkProps } from "@ui/components/navigation/link/NextLink";

export type ILinkProps = Partial<NextLinkProps> &
    Partial<LinkProps> &
    Partial<TextProps> & {
        link: ILink | IPagePath;
        children?: React.ReactNode;
    };

const getLinkType = (link: ILink | IPagePath) => {
    if (link?.sys?.contentType?.sys?.id === "pagePath") {
        return "pagePath";
    }

    const _link = link as ILink;

    const modal = _link?.fields?.modal;

    if (!isEmpty(modal)) {
        return "modal";
    }

    const internalLink = _link?.fields?.internalLink ?? _link?.fields?.deepLink;

    if (!isEmpty(internalLink)) {
        return "internalLink";
    }

    const externalUrl = _link?.fields?.externalUrl;

    if (!isEmpty(externalUrl)) {
        return "externalUrl";
    }

    return "unknown";
};

const UnknownLink = forwardRef<HTMLDivElement>((props, ref) => (
    <Flex w="full" background="red.200" px="4" py="2" justifyContent="center" ref={ref}>
        <Text color="white" fontWeight={"bold"}>
            Missing link type
        </Text>
    </Flex>
));

UnknownLink.displayName = "UnknownLink";

const linksMap = {
    modal: ModalLink,
    internalLink: InternalLink,
    externalUrl: ExternalUrl,
    pagePath: PagePath,
    unknown: UnknownLink,
};

export const Link = forwardRef<HTMLElement & HTMLParagraphElement & HTMLAnchorElement, ILinkProps>(
    ({ link, ...rest }, ref) => {
        if (isEmpty(link?.fields)) return null;

        const type = getLinkType(link);

        const Component = linksMap[type];
        return <Component link={link} ref={ref} {...rest} />;
    }
);

Link.displayName = "Link";
