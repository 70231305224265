import { isEmpty } from "lodash-es";
import { forwardRef } from "react";
import type { ILink, IPagePath } from "@contentful-api/types/contentful";
import { NextLink, type NextLinkProps } from "@ui/components/navigation/link/NextLink";
interface IPagePathProps extends Partial<NextLinkProps> {
    link: ILink | IPagePath;
}

export const PagePath = forwardRef<HTMLAnchorElement, IPagePathProps>(
    ({ link, children, ...rest }, ref) => {
        const _link = link as IPagePath;

        const href = _link?.fields?.completeSlug || "";
        const label = _link?.fields?.label || "";

        return (
            <NextLink
                _hover={{ color: "black" }}
                fontSize="inherit"
                href={href}
                ref={ref}
                {...rest}
            >
                {isEmpty(children) ? label : children}
            </NextLink>
        );
    }
);

PagePath.displayName = "PagePath";
