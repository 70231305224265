// https://developer.fastly.com/reference/io/#configuration
import type { ImageLoaderProps } from "next/image";

export type FastlyImageOptimizerParams = {
    auto?: "avif" | "webp";
    "bg-color"?: string;
    blur?: string;
    brightness?: string;
    canvas?: string;
    contrast?: string;
    crop?: string;
    disable?: "upscale";
    dpr?: string;
    enable?: "upscale";
    fit?: "cover" | "crop" | "bounds";
    format?:
        | "avif"
        | "bjpg"
        | "gif"
        | "jpg"
        | "pjpg"
        | "png"
        | "png8"
        | "webp"
        | "webpll"
        | "webply";
    frame?: "1";
    height?: string;
    level?: string;
    optimize?: "low" | "medium" | "high";
    orient?: "r" | "l" | "v" | "h" | "hv" | "vh" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
    pad?: string;
    precrop?: string;
    profile?: "baseline" | "main" | "high";
    quality?: string;
    "resize-filter"?:
        | "nearest"
        | "bilinear"
        | "linear"
        | "bicubic"
        | "cubic"
        | "lanczos2"
        | "lanczos3"
        | "lanczos";
    saturation?: string;
    sharpen?: string;
    trim?: string;
    "trim-color"?: string;
    width?: string;
};

export const aprimoLoader = (
    { src, width, quality }: ImageLoaderProps,
    imageOptimizerParams?: FastlyImageOptimizerParams
): string => {
    if (imageOptimizerParams?.auto === "avif" || imageOptimizerParams?.format === "avif") {
        process.env.CONTEXT !== "production" &&
            console.warn(
                "AVIF is a premium feature of Fastly's Image Optimizer and choosing it as an encoding format might increase your bill."
            );
    }

    const { width: _width, quality: _quality, ...restParams } = imageOptimizerParams ?? {};

    const params = new URLSearchParams(
        {
            auto: "webp",
            ...restParams,
            width: width.toString(),
            quality: quality ? quality.toString() : "85",
        } || null
    );

    return `${src}?${params.toString()}`;
};
