export type Replacements = Record<string, string | number>;

export type InterpolationRegex = (key: string) => RegExp;

const replacementString = (key: string) => `{{${key}}}`;

// Example: interpolateText('Hello {{name}}', { name: 'John' }) => 'Hello John'
export const interpolateText = (text: string, replacements: Replacements): string => {
    Object.keys(replacements).forEach((key) => {
        const regex = new RegExp(replacementString(key), "gm");
        text = text.replace(regex, replacements[key] as string);
    });

    return text;
};
