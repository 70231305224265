import type { ReactNode } from "react";
import type { ModalContentProps } from "@ui/components/overlay/modal/Modal";
import { ModalCloseButton, ModalContent, ModalHeader } from "@ui/components/overlay/modal/Modal";

interface Props extends ModalContentProps {
    header?: ReactNode;
    children?: ReactNode;
}

const Modal = ({ header, children, ...props }: Props) => {
    return (
        <ModalContent
            mt={0}
            mb={[0, "auto"]}
            containerProps={{
                justifyContent: ["center", "flex-end"],
                w: "inherit",
                right: 0,
                paddingRight: 0,
            }}
            {...props}
        >
            <ModalHeader display="flex">
                {header}
                <ModalCloseButton />
            </ModalHeader>
            {children}
        </ModalContent>
    );
};

export default Modal;
