import type {
    SelectProps as _SelectProps,
    SelectFieldProps as _SelectFieldProps,
} from "@chakra-ui/react";
import { forwardRef, Select as _Select } from "@chakra-ui/react";

export interface SelectProps extends _SelectProps {}

export interface SelectFieldProps extends _SelectFieldProps {}

export const Select = forwardRef<SelectProps, "select">(({ ...props }, ref) => (
    <_Select ref={ref} {...props} />
));
