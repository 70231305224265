import type {
    ButtonProps as _ButtonProps,
    ButtonGroupProps as _ButtonGroupProps,
} from "@chakra-ui/react";
import { forwardRef, Button as _Button, ButtonGroup as _ButtonGroup } from "@chakra-ui/react";
import { Progress } from "../../other/progress/Progress";

export interface ButtonProps extends _ButtonProps {
    text?: string;
}
export interface ButtonGroupProps extends _ButtonGroupProps {}

export const ButtonGroup = forwardRef<ButtonGroupProps, "div">(({ ...props }, ref) => (
    <_ButtonGroup ref={ref} {...props} />
));

export const Button = forwardRef<ButtonProps, "button">(({ ...props }, ref) => (
    <_Button
        ref={ref}
        spinner={<Progress variant="buttonLoader" isIndeterminate />}
        px={
            props.variant === "link"
                ? 0
                : {
                      base: 3,
                      sm: 4,
                  }
        }
        {...props}
    />
));
