import type { ThemingProps } from "@chakra-ui/react";
import { forwardRef, useStyleConfig } from "@chakra-ui/react";
import { __DEV__ } from "@chakra-ui/utils";
import type { IconProps } from "../icon/Icon";
import { Icon } from "../icon/Icon";

export interface ECCOIconProps
    extends Omit<IconProps, "orientation" | "viewBox" | "w" | "h" | "width" | "height">,
        ThemingProps<"ECCOIcon"> {
    name:
        | "arrow-left"
        | "arrow-right"
        | "bag"
        | "bin"
        | "care"
        | "chevron"
        | "close"
        | "clean"
        | "facebook"
        | "favorite"
        | "filters"
        | "finder"
        | "help"
        | "home"
        | "info"
        | "instagram"
        | "minus"
        | "navigation"
        | "ok"
        | "passwordhide"
        | "passwordshow"
        | "play"
        | "plus"
        | "profile"
        | "protect"
        | "search"
        | "tiktok"
        | "wishlist-filled"
        | "wishlist"
        | "youtube"
        | string;
    size?: "3xs" | "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";
}

const viewBoxSizes = {
    "3xs": "0 0 8 8",
    "2xs": "0 0 12 12",
    xs: "0 0 16 16",
    sm: "0 0 20 20",
    md: "0 0 24 24",
    lg: "0 0 32 32",
    xl: "0 0 40 40",
    "2xl": "0 0 64 64",
    "3xl": "0 0 80 80",
};

export const ECCOIcon = forwardRef<ECCOIconProps, "svg">(
    ({ name, sx, css, size = "md", ...props }, ref) => {
        const styles = useStyleConfig("ECCOIcon", { size });
        const viewBox = viewBoxSizes[size];

        return (
            <Icon aria-hidden="true" viewBox={viewBox} __css={styles} sx={sx} {...props} ref={ref}>
                <use xlinkHref={`#ec-${name}`} />
            </Icon>
        );
    }
);

if (__DEV__) {
    ECCOIcon.displayName = "ECCOIcon";
}
