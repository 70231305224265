import { Heading } from "@chakra-ui/layout";
import type { HeadingProps } from "@chakra-ui/react";
import { useBrowserLayoutEffect } from "../../../hooks/useBrowserLayoutEffect";
import type { ReactNode } from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { LogTag, Logger, ServiceType } from "@lib/monitoring/logger";

export interface A11yHeadingProps extends HeadingProps {
    text?: string;
    level?: string;
    children?: ReactNode;
}

interface Map {
    [key: string]: string;
}

const getElementDepthRec = (element: any, depth: any): any => {
    const par = element.parentNode;
    if (par == null) {
        return depth;
    }
    if (par.nodeName == "BODY") {
        return depth;
    }
    return getElementDepthRec(par, depth + 1);
};
const getElementDepth = (element: any): any => {
    return getElementDepthRec(element, 0);
};

export const H = forwardRef<HTMLElement, A11yHeadingProps>(
    ({ level, as, children, ...props }: A11yHeadingProps, ref) => {
        const getRandomSequence = (length = 8) =>
            Array(length)
                .fill("A")
                .map((item) => item + String.fromCharCode(Math.floor(Math.random() * 26) + 65))
                .join("");
        const headingLevel: any = level ? level : as ? as : "h1";
        const [usedHeadingLevel, setUsedHeadingLevel] = useState(headingLevel);
        const headingRef = useRef(null);

        const currentRef: any = ref || headingRef;
        const [dataTestId, setDataTestId] = useState("");

        // Generate the random ID only on the client side
        useEffect(() => {
            setDataTestId(getRandomSequence());
        }, []);
        /* We have headings showing up all over, and we don't want to have  to maintain their heading level by hand
           unfortunately this is probably the only way to be sure - that we don't get more than 1 h1, 
           and that h1 comes first on page, and that the rest of the headings follow a reasonable order.

           So on build, for crawlers, and for non-js enabled users they will get poorly structured headings,
           This means that people using screenreaders with JS disabled will not have the correct Heading level

           With JS in client side we will find all headings on page and put them in a reasonable order. 
           Obviously we should also try to fix heading order because if we do not have a heading order problem - 
           that is to say the headings are in the order that the script suggests then there will not be any changes to the state
           causing a rerender. 
        */
        useBrowserLayoutEffect(() => {
            const el = currentRef?.current;
            if (!el) return;
            if (window !== undefined) {
                /* Putting a level on a heading means that we know exactly the headings that will
                show up in this area and that level will be used as the actual as sent to Heading
                component. Thus we do not have to do the rest of this stuff for any item that has a heading on it.  */
                if (level) {
                    return;
                }
                const headings = document.querySelectorAll<HTMLElement>("h1, h2, h3, h4, h5, h6");
                let currentSuggestedHeading = "h1";
                let pertinentTop = 0;
                let pertinentDepth = 0;
                const incrementTable: Map = {
                    h1: "h2",
                    h2: "h3",
                    h3: "h4",
                    h4: "h5",
                    h5: "h6",
                };
                const returnFixedHeading = (heading: any, index: number) => {
                    let incrementedThisTime = false;
                    const { top, depth } = heading;

                    if (currentSuggestedHeading === "h6") {
                        return { ...heading, index, suggested: currentSuggestedHeading };
                    }
                    if (top > pertinentTop + 200) {
                        incrementedThisTime = true;
                        pertinentTop = top;
                        currentSuggestedHeading = incrementTable[currentSuggestedHeading];
                    }
                    if (!incrementedThisTime && depth !== pertinentDepth) {
                        pertinentDepth = depth;
                        currentSuggestedHeading = incrementTable[currentSuggestedHeading];
                    }
                    return { ...heading, index, suggested: currentSuggestedHeading };
                };
                const nodelistArray = Array.from(headings)
                    .map((heading, index) => {
                        const localLevel = heading.getAttribute("data-level");
                        return {
                            testid: heading.getAttribute("data-h"),
                            localLevel,
                            depth: getElementDepth(heading),
                            nodeName: heading.nodeName,
                            text: heading.innerText,
                            top: heading.getBoundingClientRect().top,
                            index,
                        };
                    })
                    .sort((a, b) => a.top - b.top)
                    .map((heading, index) => {
                        const { top, depth } = heading;
                        if (heading.localLevel) {
                            currentSuggestedHeading = heading.localLevel;
                            return { ...heading, index, suggested: heading.localLevel };
                        }
                        /*this can turn out to be problematic
                          basically if you are using level you are controlling the heading level
                          and not just that but you know what will happen with the other headings on that 
                          page so that we should not get any problem where your 
                          level = h3 is sorted to the top by top property because then 
                          we have h3 coming first. So if you use the level attribute
                          you should really know that you control the heading order. 
                        
                        */
                        if (index === 0) {
                            return { ...heading, index, suggested: "h1" };
                        }
                        if (index === 1) {
                            currentSuggestedHeading = "h2";
                            pertinentTop = top;
                            pertinentDepth = depth;
                            return { ...heading, index, suggested: currentSuggestedHeading };
                        }
                        return returnFixedHeading(heading, index);
                    });
                const currentHeadingInDom = nodelistArray.find(
                    (heading) => heading.testid === dataTestId
                );
                if (!currentHeadingInDom) {
                    return;
                }
                if (currentHeadingInDom.suggested !== usedHeadingLevel) {
                    Logger.warn(
                        ServiceType.WEB,
                        `Heading: ${usedHeadingLevel} with text ${currentHeadingInDom.text} is not in the correct order. Suggested: ${currentHeadingInDom.suggested}`,
                        { tag: LogTag.ACCESSIBILITY }
                    );
                    setUsedHeadingLevel(currentHeadingInDom.suggested);
                }
            }
        }, []);

        return (
            <Heading
                ref={currentRef}
                as={usedHeadingLevel}
                {...props}
                data-h={dataTestId}
                data-level={level}
            >
                {children}
            </Heading>
        );
    }
);
H.displayName = "A11yHeading";

export default H;
