import type { FormControlProps as _FormControlProps } from "@chakra-ui/react";
import { forwardRef, FormControl as _FormControl } from "@chakra-ui/react";

export type FormControlProps = { isPrivate?: boolean } & _FormControlProps;

export const FormControl = forwardRef<FormControlProps, "div">(
    ({ isPrivate = true, ...props }, ref) => (
        <_FormControl data-privacy={`${isPrivate}`} ref={ref} {...props} mt={"4"} />
    )
);
