import type {
    AccordionProps as _AccordionProps,
    AccordionItemProps as _AccordionItemProps,
    AccordionButtonProps as _AccordionButtonProps,
    AccordionPanelProps as _AccordionPanelProps,
} from "@chakra-ui/react";
import {
    forwardRef,
    Accordion as _Accordion,
    AccordionItem as _AccordionItem,
    AccordionButton as _AccordionButton,
    AccordionPanel as _AccordionPanel,
    AccordionIcon as _AccordionIcon,
} from "@chakra-ui/react";

export interface AccordionProps extends _AccordionProps {}
export interface AccordionItemProps extends _AccordionItemProps {}
export interface AccordionButtonProps extends _AccordionButtonProps {}
export interface AccordionPanelProps extends _AccordionPanelProps {}

export const Accordion = forwardRef<AccordionProps, "div">(({ ...props }, ref) => (
    <_Accordion ref={ref} {...props} />
));

export const AccordionItem = forwardRef<AccordionItemProps, "div">(({ ...props }, ref) => (
    <_AccordionItem ref={ref} {...props} />
));

export const AccordionButton = forwardRef<AccordionButtonProps, "button">(({ ...props }, ref) => (
    <_AccordionButton ref={ref} {...props} />
));

export const AccordionPanel = forwardRef<AccordionPanelProps, "div">(({ ...props }, ref) => (
    <_AccordionPanel ref={ref} {...props} />
));

export const AccordionIcon = _AccordionIcon;
