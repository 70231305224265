import type { Options as RenderOptions } from "@contentful/rich-text-react-renderer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { Document } from "@contentful/rich-text-types";
import type { Options } from "./richTextOptions";
import { getOptions } from "./richTextOptions";

export const renderRichTextToComponent = (
    data: Document,
    renderOptions?: RenderOptions,
    options?: Options
) => {
    const defaultOptions = getOptions(options);
    const currentOptions = renderOptions
        ? unionOptions(defaultOptions, renderOptions)
        : defaultOptions;
    return documentToReactComponents(data as any, currentOptions);
};

const unionOptions = (
    defaultOptions: RenderOptions,
    passedOptions: RenderOptions
): RenderOptions => {
    return {
        renderMark: { ...defaultOptions.renderMark, ...passedOptions.renderMark },
        renderNode: { ...defaultOptions.renderNode, ...passedOptions.renderNode },
        renderText: passedOptions.renderText ?? defaultOptions.renderText,
    };
};
