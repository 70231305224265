import type { BoxProps as _BoxProps } from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/react";
import { removePropsFromHTML } from "../../../utils/removeProps";
import { Box as _Box } from "@chakra-ui/layout";

export interface BoxProps extends _BoxProps {}

export const Box = forwardRef<BoxProps, "div">(({ ...props }, ref) => (
    <_Box ref={ref} {...removePropsFromHTML(props)} />
));
