const Wishlist = {
    title: "wishlist.title",
    emptyListText: "wishlist.emptyList.text",
    addedToWishlistTitle: "uiText.addedToWishlist.title",
    removedFromWishlistTitle: "uiText.removedFromWishlist.title",
    addAllToBagTitle: "uiText.addAllToBag.title",
    addAllToBagDescription: "uiText.wishlistAddAlltoBag.description",
    button: {
        viewWishList: "uiText.button.viewWishList",
        undoWishlist: "uiText.button.undoWishlist",
        addAllToBag: "uiText.button.addAllToBag",
    },
};

export default Wishlist;
