import { useToast } from "@ui/components/feedback/toast/useToast";

export type ToastErrorMessage = {
    title?: string;
    description?: string;
};

export const useErrorToast = (duration = 9000) => {
    const { toast } = useToast();

    return ({ title, description }: ToastErrorMessage) => {
        return toast({
            title,
            description,
            status: "error",
            duration,
        });
    };
};
