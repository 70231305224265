import type {
    ModalProps as _ModalProps,
    ModalOverlayProps as _ModalOverlayProps,
    ModalContentProps as _ModalContentProps,
    ModalHeaderProps as _ModalHeaderProps,
    ModalFooterProps as _ModalFooterProps,
    ModalBodyProps as _ModalBodyProps,
    CloseButtonProps as _ModalCloseButtonProps,
} from "@chakra-ui/react";
import {
    forwardRef,
    Modal as _Modal,
    ModalOverlay as _ModalOverlay,
    ModalContent as _ModalContent,
    ModalHeader as _ModalHeader,
    ModalFooter as _ModalFooter,
    ModalBody as _ModalBody,
    ModalCloseButton as _ModalCloseButton,
} from "@chakra-ui/react";
import { sizes } from "../../../design-tokens";
import { ECCOIcon } from "../../../components/media-and-icons/ecco-icon/ECCOIcon";
export interface ModalProps extends _ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export interface ModalOverlayProps extends _ModalOverlayProps {}
export interface ModalContentProps extends _ModalContentProps {}
export interface ModalHeaderProps extends _ModalHeaderProps {}
export interface ModalFooterProps extends _ModalFooterProps {}
export interface ModalBodyProps extends _ModalBodyProps {}
export interface ModalCloseButtonProps extends _ModalCloseButtonProps {}

export const Modal: React.FC<ModalProps> = ({ ...props }) => <_Modal {...props} />;

export const ModalOverlay = forwardRef<any, "div">(({ ...props }, ref) => (
    <_ModalOverlay pr={0} ref={ref} {...props} />
));

export const ModalContent = forwardRef<ModalContentProps, "section">(({ ...props }, ref) => (
    <_ModalContent ref={ref} {...props} />
));

export const ModalHeader = forwardRef<ModalHeaderProps, "header">(({ ...props }, ref) => (
    <_ModalHeader ref={ref} {...props} />
));

export const ModalFooter = forwardRef<ModalFooterProps, "footer">(({ ...props }, ref) => (
    <_ModalFooter ref={ref} {...props} />
));

export const ModalBody = forwardRef<_ModalBodyProps, "div">(({ ...props }, ref) => (
    <_ModalBody ref={ref} {...props} />
));

export const ModalCloseButton = forwardRef<ModalCloseButtonProps, "button">(
    ({ sx, ...props }, ref) => (
        <_ModalCloseButton
            sx={{
                position: "absolute",
                top: 0,
                right: 0,
                marginLeft: "auto",
                _hover: { bgColor: "gray.800" },
                _active: { bgColor: "black" },
                ...sx,
            }}
            ref={ref}
            aria-label="Close"
            bgColor="black"
            color="white"
            {...props}
            height={`${sizes.icon["4xs"]}`}
            width={`${sizes.icon["4xs"]}`}
        >
            <ECCOIcon name="close" color="currentColor" />
        </_ModalCloseButton>
    )
);
