import { useGlobalModalContext } from "@components/GlobalModalProvider";
import type { ILink, IPagePath } from "@contentful-api/types/contentful";
import type { TextProps } from "@ui/components/content/text/Text";
import { Text } from "@ui/components/content/text/Text";
import { isEmpty } from "lodash-es";
import { forwardRef } from "react";

interface IModalLinkProps extends TextProps {
    link: ILink | IPagePath;
}

export const ModalLink = forwardRef<HTMLParagraphElement, IModalLinkProps>(
    ({ link, children, ...rest }, ref) => {
        const _link = link as ILink;
        const label = _link?.fields?.label || "";
        const description = _link?.fields?.description || "";

        const { showModal } = useGlobalModalContext();

        const onClick = () => {
            showModal(_link?.fields?.modal, { description });
        };

        return (
            <Text
                data-type="modal-link"
                as="button"
                color="inherit"
                fontSize="inherit"
                cursor="pointer"
                onClick={onClick}
                {...rest}
                ref={ref}
            >
                {isEmpty(children) ? label : children}
            </Text>
        );
    }
);

ModalLink.displayName = "ModalLink";
