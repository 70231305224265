import { iconButtonStyles } from "./IconButton.styles";

import type { IconButtonProps as _IconButtonProps } from "@chakra-ui/react";
import { IconButton as _IconButton, forwardRef } from "@chakra-ui/react";

export interface IconButtonProps extends _IconButtonProps {}

export const IconButton = forwardRef<IconButtonProps, "button">(({ ...props }, ref) => (
    <_IconButton ref={ref} sx={{ ...iconButtonStyles, ...props.sx }} {...props} />
));
